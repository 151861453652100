import React from 'react';
import { Link } from 'gatsby';

const countTag = (books) => {
  const bookCount = (books) ? books.length: 0;
  let countString;

  if (bookCount === 0) {
    countString = '0 Books';
  } else if (bookCount === 1) {
    countString = '1 Book';
  } else {
    countString = `${bookCount} Books`;
  }

  return `(${countString})`;
}

export default (props) => {
  if (props.slug && props.label && props.slug.length > 0 && props.label.length > 0) {
    return (
      <div className="taxonomy-grid__cell">
        <Link to={`${props.pathStem}/${props.slug}`}>
          <span className="label">{ props.label }</span>
          <span className="count">{ countTag(props.books) }</span>
        </Link>
      </div>
    );
  }

  return null;
};