import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import BaseLayout from '../components/layout/base-layout';
import CatalogHeader from '../components/common/catalog-header';
import PageColumn from '../components/layout/page-column';
import RainbowBar from '../components/common/rainbow-bar';
import Section from '../components/layout/section';
import TaxonomyGrid from '../components/common/taxonomy/grid';

export default (props) => {
  let authorNodes = props.data.authors.edges.map(({ node }) => node);
  authorNodes = authorNodes.filter(author => {
    let bookNodes = author.data.Books || [];
    const publishedBookNodes = bookNodes.filter(book => book.data.Published);

    return publishedBookNodes.length > 0;
  });

  return (
    <BaseLayout>
      <Helmet>
        <title>Browse Cookbooks by Author</title>
        <meta
          property="og:title"
          content="Browse Cookbooks by Author"
        />
      </Helmet>
      <CatalogHeader
        label="Browse Cookbooks by Author"
      />
      <RainbowBar width={'thin'} />
      <Section topSpacing="section" bottomSpacing="section">
        <PageColumn>
          <TaxonomyGrid
            nodes={ authorNodes }
            labelProperty='Name'
            pathStem='/author'
          />
        </PageColumn>
      </Section>
    </BaseLayout>
  );
}

export const query = graphql`
{
  authors:allAirtable(filter:{table:{eq:"Authors"}},
              sort:{fields: data___Sort_Name, order:ASC}) {
    edges {
      node {
        data {
          Name
          Slug
          Books {
            data {
              Slug
              Published
            }
          }
        }
      }
    }
  }
}
`;
