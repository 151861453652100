import React from 'react';

import TaxonomyGridCell from './grid-cell';

import '../../../styles/common/taxonomy/grid.scss';

export default (props) => {
  const nodes = props.nodes;

  return (
    <div class="taxonomy-grid">
      {nodes.map(({ data }) => (
        <TaxonomyGridCell
          key={ data.Slug }
          label={ data[props.labelProperty] }
          slug={ data.Slug }
          books={ data.Books }
          pathStem={ props.pathStem }
        />
      ))}
    </div>
  );
};