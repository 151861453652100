import React from 'react';

import PageColumn from '../../components/layout/page-column';
import Section from '../../components/layout/section';

import '../../styles/common/catalog-header.scss';

export default (props) => (
  <Section topSpacing="subsection" bottomSpacing="subsection" color="yellow">
    <PageColumn>
      <h1 className="catalog-header">
        { props.label }
      </h1>
    </PageColumn>
  </Section>
);